import * as Type from './type';
import RPC from 'api';
import ability from '../../../ability';
import { setPanelArr, setTitle} from 'publicStore/base/public/action';
let wholeTitleMenu = null;//完整的titleMenu
function updateFishEyeCaps (data) {
    return {
        type:Type.UPDATE_FISHEYE_CAPS,
        data
    };

}
export function updateFishEyeData (data) {
    return {
        type:Type.UPDATE_FISHEYE_DATA,
        data
    };
}
export function updateFishEyeConfig (data) {
    return {
        type:Type.UPDATE_FISHEYE_CONFIG,
        data
    };
}
export const getFishEyeCaps = (channel = 0) => (dispatch, getState, resolve, reject) => {
    Promise.all([ability.get('VideoInputCaps', null, channel), ability.get('VideoInputCapsEx', null, channel)]).then(([caps, capsEx]) => {
        if (caps.FishEye && capsEx.VideoInFishEye) {//支持鱼眼
            dispatch(updateFishEyeCaps(capsEx.VideoInFishEye));
            resolve(capsEx.VideoInFishEye);
        } else {
            dispatch(updateFishEyeCaps(false));
            resolve(false);
        }
    });
};
export const getFishEyeConfig = (channel = 0) => (dispatch, getState, resolve, reject) => {
    let {publicState: {panel, titleMenu},webCaps} = getState();
    RPC.ConfigManager.getConfig('FishEye').then((json) => {
        const CalibrateMode = json[channel].CalibrateMode;
        // 鱼眼韩国车位检测4画面模式需要显示智能规则控件
        let isFourShow = webCaps.isKoreaParking && CalibrateMode === 'FourEPtzRegion';
        let hideIVS = !((CalibrateMode === 'Original' || isFourShow) && json[channel].PlaceHolder === 1);
        dispatch(updateFishEyeData({
            config: json,
            isOriginal: CalibrateMode === 'Original',
            hideIVS,
            ocxInstallMode: json[channel].PlaceHolder,
            ocxCalibrateMode: '1'
        }));
        if (!wholeTitleMenu) {
            wholeTitleMenu = [...titleMenu];
        }
        //非顶装原始圆和智能互斥，隐藏头部的导航标签
        if (hideIVS) {
            let excludes = ['AIConfig', 'report', 'FaceAnalysisConfig'];
            let newPanel = [];
            let newTitleMenu = [];
            panel.forEach(menu => {
                if (!excludes.includes(menu.FamilyKey)) {
                    newPanel.push(menu);
                }
            });
            titleMenu.forEach(menu => {
                if (!excludes.includes(menu.FamilyKey)) {
                    newTitleMenu.push(menu);
                }
            });
            if (newPanel.length) {
                dispatch(setPanelArr(newPanel));
            }
            if (newTitleMenu.length) {
                dispatch(setTitle(newTitleMenu));
            }
            //关闭智能
            RPC.ConfigManager.getConfig('VideoAnalyseGlobal').then(VAG => {
                if (VAG[channel] && VAG[channel].Scene) {
                    VAG[channel].Scene.Type = null;
                    VAG[channel].Scene.TypeList = null;
                    RPC.ConfigManager.setConfig('VideoAnalyseGlobal', VAG);
                }
            });
        } else {
            dispatch(setTitle(wholeTitleMenu));
        }
        resolve(json);
    }).catch(() => {
        reject();
    });
};
export const setFishEyeConfig = () => (dispatch, getState, resolve, reject) => {
    let {FishEye: {config}} = getState();
    RPC.ConfigManager.setConfig('FishEye', config).then((json) => {
        resolve();
    }).catch(() => {
        reject();
    });
};
/**
 * 更新viewAdjust
 */
export const updateViewAdjust = (data) => (dispatch) => {
    dispatch({
        type: Type.UPDATE_VIEW_ADJUST,
        data: data
    });
};
